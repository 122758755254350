import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";

interface IProps {
  imageTitle: string;
  imageUrl?: string | null;
}

const ImageCard = ({ imageTitle, imageUrl }: IProps) => {
  return (
    <Card>
      <CardHeader title={imageTitle} />
      <CardContent sx={{ textAlign: "center" }}>
        {imageUrl !== undefined && imageUrl !== null ? (
          <>
            <img alt="Product/packaging image" width={200} src={imageUrl} />
          </>
        ) : (
          <Skeleton height={200} width={200} />
        )}
      </CardContent>
    </Card>
  );
};

export default ImageCard;
