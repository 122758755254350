import { SelectField } from "@curaleaf-international/components";

export const MICROBE_STANDARD = {
  EU_5_1_4: "EU_5_1_4",
  EU_5_1_8b: "EU_5_1_8b",
  EU_5_1_8c: "EU_5_1_8c",
};

interface IProps {
  fullWidth?: boolean;
  helperText?: string;
  label: string;
  multiple?: boolean;
  name: string;
  required?: boolean;
}

const MicrobeStandardField = ({
  fullWidth,
  helperText,
  label,
  multiple,
  name,
  required,
}: IProps) => (
  <SelectField
    autoComplete="off"
    fullWidth={fullWidth}
    helperText={helperText}
    label={label}
    multiple={multiple}
    name={name}
    options={Object.entries(MICROBE_STANDARD).map(([value, label]) => ({
      label,
      value,
    }))}
    required={required}
  />
);

export default MicrobeStandardField;
