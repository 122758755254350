import { DashboardTile } from "@curaleaf-international/components";

import { FormulaState } from "src/models";
import { useFormulasQuery } from "src/queries";

const Dashboard = () => {
  const { data: formulas } = useFormulasQuery({
    approved: false,
    paginationSettings: {
      pageNumber: 0,
      pageSize: 100,
      sortColumn: "id",
      sortDirection: "desc",
    },
    state: FormulaState.ACTIVE,
  });

  return (
    <DashboardTile
      tileDescription="Unapproved formula"
      tileValue={formulas?.totalRecordCount.toString() ?? ""}
    />
  );
};

export default Dashboard;
