import { z } from "zod";

export const formulaImageSchema = z.object({
  packagingAssetKey: z.string().nullable(),
  productAssetKey: z.string().nullable(),
  packagingUrl: z.string().nullable(),
  productUrl: z.string().nullable(),
});

export type FormulaImages = z.infer<typeof formulaImageSchema>;

export const newFormulaImages = (data: unknown): FormulaImages =>
  formulaImageSchema.parse(data);
