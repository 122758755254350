import { Page, Value } from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link } from "wouter";

import RequiresRoles from "src/components/RequiresRoles";
import { StaffRole } from "src/models";
import { useBrandQuery } from "src/queries";

interface IProps {
  brandId: number;
}

const Brand = ({ brandId }: IProps) => {
  const { data: brand } = useBrandQuery(brandId);

  return (
    <Page
      actions={
        <RequiresRoles roles={[StaffRole.DEVELOPER, StaffRole.DATA_MASTER]}>
          <Button
            component={Link}
            to={`/brands/${brandId}/edit/`}
            variant="contained"
          >
            Edit
          </Button>
        </RequiresRoles>
      }
      breadcrumbs={[
        {
          to: "/brands/",
          label: "Brands",
        },
      ]}
      title={brand?.name ?? ""}
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Brand name</TableCell>
            <TableCell>
              <Value text={brand?.name} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>
              <Value text={brand?.company} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Page>
  );
};
export default Brand;
