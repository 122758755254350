import {
  cz,
  FormLinks,
  Page,
  TextField,
  SubmitButton,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import {
  useFormulaImagesQuery,
  useEditImageMutation,
  useFormulaQuery,
} from "src/queries/formulas";

const FormSchema = z.object({
  packagingAssetKey: cz.field(z.string().nullable()),
  productAssetKey: cz.field(z.string().nullable()),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  formulaId: string;
}

const EditFormulaImages = ({ formulaId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: image } = useFormulaImagesQuery(formulaId);
  const { data: formula } = useFormulaQuery(formulaId);
  const { mutateAsync: edit } = useEditImageMutation(formulaId);

  const defaultValues = {
    packagingAssetKey: image?.packagingAssetKey ?? "",
    productAssetKey: image?.productAssetKey ?? "",
  };
  const methods = useForm<FormType>({
    defaultValues: defaultValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [image !== undefined]);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit(data);
      addToast("Asset key updated", "success");
      setLocation(`/formulas/${formulaId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <Page
      breadcrumbs={[
        {
          to: "/formulas/",
          label: "Formulas",
        },
        {
          to: `/formulas/${formulaId}/`,
          label: `${formula?.nameInternal}`,
        },
        { label: "Edit formula" },
      ]}
      disablePadding={true}
      title={`Edit asset keys`}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CardContent>
            <TextField
              fullWidth
              name="productAssetKey"
              label="Brandfolder asset key for product image"
              autoComplete="off"
            />
            <TextField
              fullWidth
              name="packagingAssetKey"
              label="Brandfolder packaging key for packaging image"
              autoComplete="off"
            />
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label={"Save"} />
            <FormLinks
              links={[{ label: "Back", to: `/formulas/${formulaId}/` }]}
            />
          </CardActions>
        </form>
      </FormProvider>
    </Page>
  );
};

export default EditFormulaImages;
