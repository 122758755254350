import {
  cz,
  TextField,
  SubmitButton,
  FormLinks,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const FormSchema = z.object({
  name: cz.field(z.string()),
  company: cz.field(z.string()),
});
export type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  defaultValues: FormType;
  disabled?: boolean;
  label: string;
  onSubmit: (data: ValidatedType) => Promise<void>;
}

const BrandForm = ({ defaultValues, disabled, label, onSubmit }: IProps) => {
  const methods = useForm<FormType, any, ValidatedType>({
    defaultValues,
    disabled,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CardContent>
          <TextField
            autoComplete="off"
            fullWidth
            label="Brand name"
            name="name"
            required
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Company"
            name="company"
            required
          />
        </CardContent>
        <Divider />
        <CardActions>
          <SubmitButton label={label} />
          <FormLinks links={[{ label: "Back", to: "/brands/" }]} />
        </CardActions>
      </form>
    </FormProvider>
  );
};

export default BrandForm;
