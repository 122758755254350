import { PageWrapper as CuraPageWrapper } from "@curaleaf-international/components";
import axios from "axios";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import IconLogo from "src/components/IconLogo";
import Logo from "src/components/Logo";
import RoleExpiryBanner from "src/components/RoleExpiryBanner";
import SolarFileCheckLinear from "src/icons/SolarFileCheckLinear";
import SolarHospitalLinear from "src/icons/SolarHospitalLinear";
import SolarQuestionCircleLinear from "src/icons/SolarQuestionCircleLinear";
import SolarRulerCrossPenLinear from "src/icons/SolarRulerCrossPenLinear";
import SolarTestTubeLinear from "src/icons/SolarTestTubeLinear";
import SolarUsersGroupTwoRoundedLinear from "src/icons/Staff";
import { StaffRole } from "src/models";
import { useMutation } from "src/query";

interface IProps {
  children: React.ReactNode;
}

const PageWrapper = ({ children }: IProps) => {
  const { member, unauthenticate } = useContext(AuthContext);
  const { mutateAsync: logout } = useMutation({
    mutationFn: async () => {
      await axios.delete("/v1/staff-sessions/");
    },
    onSuccess: () => unauthenticate(),
  });

  const links = [
    {
      icon: <SolarHospitalLinear />,
      label: "Brands",
      to: "/brands/",
    },
    {
      icon: <SolarTestTubeLinear />,
      label: "Formulas",
      to: "/formulas/",
    },
  ];

  if (
    member?.roles.some((role) =>
      [StaffRole.DEVELOPER, StaffRole.QUALITY].includes(role),
    )
  ) {
    links.push({
      icon: <SolarFileCheckLinear />,
      label: "Quality approvals",
      to: "/quality/",
    });
  }

  if (
    member?.roles.some((role) =>
      [StaffRole.DEVELOPER, StaffRole.STAFF_MANAGER].includes(role),
    )
  ) {
    links.push({
      icon: <SolarUsersGroupTwoRoundedLinear />,
      label: "Staff",
      to: "/staff/",
    });
    links.push({
      icon: <SolarRulerCrossPenLinear />,
      label: "Activity log",
      to: "/activities/",
    });
    links.push({
      icon: <SolarQuestionCircleLinear />,
      label: "Role access",
      to: "/help/endpoint-roles/",
    });
  }

  return (
    <CuraPageWrapper
      authenticated={member !== null && member !== undefined}
      banner={<RoleExpiryBanner />}
      children={children}
      links={links}
      logos={{
        large: <Logo height={36} />,
        small: <IconLogo height={20} />,
      }}
      logout={logout}
      memberEmail={member?.email}
    />
  );
};

export default PageWrapper;
