import { z } from "zod";

export enum LeafletAudience {
  HEALTH_CARE_PROFESSIONAL = "HEALTH_CARE_PROFESSIONAL",
  PATIENT = "PATIENT",
}

const leafletSchema = z.object({
  id: z.number().int().positive(),
  approved: z.coerce.date().nullable(),
  approverEmail: z.string().nullable(),
  approverId: z.number().int().nullable(),
  audience: z.nativeEnum(LeafletAudience),
  formulaId: z.string(),
  name: z.string(),
  qualityReference: z.string().nullable(),
  timestamp: z.coerce.date(),
  uploaderEmail: z.string(),
  uploaderId: z.number().int().positive(),
});

export type Leaflet = z.infer<typeof leafletSchema>;

export const newLeaflet = (data: unknown): Leaflet => leafletSchema.parse(data);
