import {
  cz,
  FormLinks,
  Page,
  SelectField,
  SubmitButton,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { LeafletAudience } from "src/models";
import {
  useEditLeafletAudienceMutation,
  useFormulaQuery,
  useLeafletQuery,
} from "src/queries";

const FormSchema = z.object({
  audience: cz.field(z.string()),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  leafletId: number;
}

const EditLeafletAudience = ({ leafletId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: leaflet } = useLeafletQuery(leafletId);
  const { data: formula } = useFormulaQuery(leaflet?.formulaId);
  const { mutateAsync: edit } = useEditLeafletAudienceMutation(leafletId);
  const defaultValues = {
    audience: leaflet?.audience ?? LeafletAudience.PATIENT,
  };
  const methods = useForm<FormType, any, ValidatedType>({
    defaultValues,
    disabled: leaflet === undefined,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => methods.reset(defaultValues), [leaflet === undefined]);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        audience: data.audience as LeafletAudience,
      });
      addToast("Leaflet updated", "success");
      setLocation(`/formulas/${leaflet?.formulaId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <Page
      breadcrumbs={[
        { to: "/formulas/", label: "Formulas" },
        {
          to: `/formulas/${leaflet?.formulaId}/`,
          label: formula?.nameInternal ?? "",
        },
      ]}
      disablePadding={true}
      title="Update Leaflet Audience"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CardContent>
            <SelectField
              autoComplete="off"
              fullWidth
              label="Leaflet Audience"
              name="audience"
              options={[
                { value: "" },
                ...Object.values(LeafletAudience).map((value) => ({
                  value,
                })),
              ]}
              required
            />
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label="Change" />
            <FormLinks
              links={[
                { label: "Back", to: `/formulas/${leaflet?.formulaId}/` },
              ]}
            />
          </CardActions>
        </form>
      </FormProvider>
    </Page>
  );
};

export default EditLeafletAudience;
