import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { newBrand, Brand } from "src/models/brand";
import { useMutation, useQuery } from "src/query";

export const useBrandsQuery = () => {
  return useQuery<Brand[]>({
    queryKey: ["brands"],
    queryFn: async ({ signal }) => {
      const response = await axios.get("/v1/brands/", { signal });
      return response.data.brands.map((brand: unknown) => newBrand(brand));
    },
  });
};

export const useBrandQuery = (brandId: number) => {
  return useQuery<Brand>({
    queryKey: ["brands", brandId],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/brands/${brandId}/`, {
        signal,
      });
      return newBrand(response.data);
    },
  });
};

interface IBrandData {
  company: string;
  name: string;
}

export const useCreateBrandMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IBrandData) =>
      await axios.post("/v1/brands/", data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["brands"] });
    },
  });
};

export const useEditBrandMutation = (brandId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IBrandData) =>
      await axios.put(`/v1/brands/${brandId}/`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["brands"] });
    },
  });
};
