import {
  cz,
  sortByKey,
  DateField2 as DateField,
  Page,
  SelectField,
  SubmitButton,
  formatDateTime,
  SkeletonRow,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import axios from "axios";
import { formatISO, parseISO, subDays } from "date-fns";
import { ReactNode, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { Activity, ActivityType } from "src/models";
import { useActivitiesQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties = "activity" | "timestamp";

export const activityTypeList = (): { value: ActivityType }[] => {
  return Object.values(ActivityType).map((activity) => ({
    value: activity,
  }));
};

const FormSchema = z.object({
  activity: cz.field(z.nativeEnum(ActivityType).nullable()),
  after: cz.field(z.coerce.date()),
  before: cz.field(z.coerce.date().nullable()),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const Activities = () => {
  const [_, setLocation] = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const activity = (searchParams.get("activity") as ActivityType) ?? undefined;
  const after = searchParams.has("after")
    ? parseISO(searchParams.get("after") ?? "")
    : subDays(new Date(), 1);
  const before = searchParams.has("before")
    ? parseISO(searchParams.get("before") ?? "")
    : undefined;
  const { data: activities, error } = useActivitiesQuery(
    activity,
    before,
    after,
  );
  const [order, setOrder] = useState<Direction>("desc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("timestamp");

  const sortKey = (activities: Activity) => [activities[orderBy]];

  let rows: ReactNode | ReactNode[] = <SkeletonRow cols={5} />;
  let sortedActivities;
  if (axios.isAxiosError(error) && error.response?.status === 413) {
    rows = (
      <TableRow>
        <TableCell colSpan={5}>
          Too much data requested: Maximum is 2 days
        </TableCell>
      </TableRow>
    );
  } else if (activities !== undefined && activities.length > 0) {
    sortedActivities = activities.sort(sortByKey(sortKey, order));
    rows = sortedActivities.map((activity) => (
      <TableRow key={`${activity.activity}-${activity.timestamp.valueOf()}`}>
        <TableCell>{formatDateTime(activity.timestamp)}</TableCell>
        <TableCell>{activity.activity}</TableCell>
        <TableCell>{activity.staffMemberEmail}</TableCell>
        <TableCell>
          <code>{JSON.stringify(activity.data)}</code>
        </TableCell>
      </TableRow>
    ));
  } else if (activities !== undefined) {
    rows = (
      <TableRow>
        <TableCell colSpan={5}>No activities found</TableCell>
      </TableRow>
    );
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const methods = useForm<FormType, any, ValidatedType>({
    defaultValues: {
      activity: "",
      before: before ? formatISO(before, { representation: "date" }) : "",
      after: formatISO(after, { representation: "date" }),
    },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = (data: ValidatedType) => {
    const params: any = {
      after: formatISO(data.after, { representation: "date" }),
    };
    if (data.activity) {
      params["activity"] = data.activity;
    }
    if (data.before) {
      params["before"] = formatISO(data.before, { representation: "date" });
    }
    const newSearchParams = new URLSearchParams(params);
    setLocation(`/activities/?${newSearchParams.toString()}`, {
      replace: true,
    });
  };

  return (
    <Page title="Activities">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid
            alignItems="center"
            container
            spacing={2}
            sx={{ marginBottom: 2 }}
          >
            <Grid size={{ xs: 12, sm: 3 }}>
              <SelectField
                fullWidth
                label="Activity"
                name="activity"
                options={[{ value: "" }, ...activityTypeList()]}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }}>
              <DateField fullWidth label="Before" name="before" />
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }}>
              <DateField fullWidth label="After" name="after" required />
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }}>
              <SubmitButton fullWidth label="Filter" />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "timestamp"}
                  direction={order}
                  onClick={onSortClick("timestamp")}
                >
                  Timestamp
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "activity"}
                  direction={order}
                  onClick={onSortClick("activity")}
                >
                  Activity
                </TableSortLabel>
              </TableCell>
              <TableCell>Staff</TableCell>
              <TableCell>Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </Page>
  );
};

export default Activities;
