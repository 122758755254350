import {
  Page,
  SelectInput,
  SkeletonRow,
} from "@curaleaf-international/components";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { Link as WLink } from "wouter";

import PaginatedSearch from "src/components/PaginatedSearch";
import TablePagination from "src/components/TablePagination";
import TableSortLabel from "src/components/TableSortLabel";
import { QualityCheck } from "src/models";
import { useQualityChecksQuery } from "src/queries";
import { usePaginationSettings } from "src/queries";

const Quality = () => {
  const [state, setState] = useState("unapproved");
  const [
    paginationSettings,
    { setPageNumber, setPageSize, toggleSort, setSearchQuery },
  ] = usePaginationSettings<QualityCheck>({
    pageNumber: 0,
    pageSize: 100,
    sortColumn: "nameInternal",
    sortDirection: "desc",
  });

  const { data: checks } = useQualityChecksQuery({
    approved: state === "approved",
    paginationSettings,
    searchTerm: paginationSettings.searchQuery,
  });
  const { totalRecordCount } = checks ?? {};

  let rows: React.ReactNode = <SkeletonRow cols={4} />;

  if (checks !== undefined && checks.rows.length > 0) {
    rows = checks.rows.map((check) => (
      <TableRow key={check.formulaId}>
        <TableCell>
          <Link component={WLink} to={`/formulas/${check.formulaId}/`}>
            {check.nameInternal}
          </Link>
        </TableCell>
        <TableCell>{check.registeredName}</TableCell>
        <TableCell>{check.qualityReference}</TableCell>
        <TableCell>{check.leafletType}</TableCell>
      </TableRow>
    ));
  } else if (checks !== undefined) {
    rows = (
      <TableRow>
        <TableCell colSpan={4}>No checks found</TableCell>
      </TableRow>
    );
  }

  return (
    <Page title="Quality">
      <PaginatedSearch
        setSearchQuery={setSearchQuery}
        paginationSettings={paginationSettings}
      />
      <SelectInput
        label="Approved"
        onChange={(value: string) => setState(value)}
        options={[
          { label: "Approved", value: "approved" },
          { label: "Unapproved", value: "unapproved" },
        ]}
        value={state}
      />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel<QualityCheck>
                  columnName="nameInternal"
                  paginationSettings={paginationSettings}
                  toggleSort={toggleSort}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel<QualityCheck>
                  columnName="registeredName"
                  paginationSettings={paginationSettings}
                  toggleSort={toggleSort}
                >
                  Registered Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel<QualityCheck>
                  columnName="qualityReference"
                  paginationSettings={paginationSettings}
                  toggleSort={toggleSort}
                >
                  Quality Reference
                </TableSortLabel>
              </TableCell>
              <TableCell>Leaflet Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
        <TablePagination<QualityCheck>
          recordCount={totalRecordCount}
          paginationSettings={paginationSettings}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          rowsPerPageOptions={[100, 500, 750, 1000]}
        />
      </TableContainer>
    </Page>
  );
};

export default Quality;
