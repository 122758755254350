import {
  Page,
  SelectInput,
  SkeletonRow,
} from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { AuthContext } from "src/AuthContext";
import { FORM } from "src/components/FormField";
import { MARKETS } from "src/components/MarketField";
import { MICROBE_STANDARD } from "src/components/MicrobeStandardField";
import PaginatedSearch from "src/components/PaginatedSearch";
import TablePagination from "src/components/TablePagination";
import TableSortLabel from "src/components/TableSortLabel";
import { Brand } from "src/models";
import { Formula, FormulaState, MicrobeStandard } from "src/models";
import { Form } from "src/models";
import { useBrandsQuery } from "src/queries";
import { usePaginationSettings } from "src/queries";
import { useFormulasQuery } from "src/queries";

const Formulas = () => {
  const { member } = useContext(AuthContext);
  const [brands, setBrands] = useState("all");
  const [form, setForm] = useState("all");
  const [market, setMarket] = useState("all");
  const [microbeStandard, setMicrobeStandard] = useState("all");
  const [state, setState] = useState("active");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [
    paginationSettings,
    { setPageNumber, setPageSize, toggleSort, setSearchQuery },
  ] = usePaginationSettings<Formula>({
    pageNumber: 0,
    pageSize: 100,
    sortColumn: "nameInternal",
    sortDirection: "desc",
  });

  const { data: formulas } = useFormulasQuery({
    paginationSettings,
    brandId: brands === "all" ? undefined : Number(brands),
    form: form === "all" ? undefined : (form as Form),
    market: market === "all" ? undefined : market,
    microbeStandard:
      microbeStandard === "all"
        ? undefined
        : (microbeStandard as MicrobeStandard),
    state: state === "active" ? FormulaState.ACTIVE : FormulaState.ARCHIVED,
    searchTerm: paginationSettings.searchQuery,
  });

  const { totalRecordCount } = formulas || {};

  const { data: brandsList } = useBrandsQuery();

  let rows: React.ReactNode = <SkeletonRow cols={5} />;

  if (formulas !== undefined && formulas.rows.length > 0) {
    rows = formulas.rows.map((formula) => (
      <TableRow key={formula.id}>
        <TableCell>
          <Link component={WLink} to={`/formulas/${formula.id}/`}>
            {formula.nameInternal}
          </Link>
        </TableCell>
        <TableCell>{formula.market.toUpperCase()}</TableCell>
        <TableCell>{formula.form}</TableCell>
        <TableCell>{formula.brandName}</TableCell>
        <TableCell>{formula.microbeStandard}</TableCell>
      </TableRow>
    ));
  } else if (formulas !== undefined) {
    rows = (
      <TableRow>
        <TableCell colSpan={5}>No formulas found</TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem component={WLink} to="/formulas/new/">
          New
        </MenuItem>
        <MenuItem
          component="a"
          href="/v1/formulas/csv/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download CSV
        </MenuItem>
        <MenuItem component={WLink} to="/formulas/upload-csv/">
          Upload CSV
        </MenuItem>
      </Menu>
      <Page
        actions={
          (member?.roles.length ?? 0) > 0 ? (
            <>
              <Button
                component={WLink}
                endIcon={<KeyboardArrowDownIcon />}
                onClick={(event) => setAnchorEl(event.currentTarget)}
                variant="contained"
              >
                Actions
              </Button>
            </>
          ) : null
        }
        title="Formulas"
      >
        <Grid container columnSpacing={2} sx={{ marginBottom: 2 }}>
          <Grid size={12}>
            <PaginatedSearch
              setSearchQuery={setSearchQuery}
              paginationSettings={paginationSettings}
            />
          </Grid>
          <Grid size={4}>
            <SelectInput
              label="State"
              onChange={(value: string) => setState(value)}
              options={[
                { label: "Active", value: "active" },
                { label: "Archived", value: "archived" },
              ]}
              value={state}
            />
          </Grid>
          <Grid size={4}>
            <SelectInput
              label="Market"
              onChange={(value: string) => setMarket(value)}
              options={[
                { label: "All", value: "all" },
                ...Object.entries(MARKETS).map(([value, label]) => ({
                  label,
                  value,
                })),
              ]}
              value={market}
            />
          </Grid>
          <Grid size={4}>
            <SelectInput
              label="Brand"
              onChange={(value: string) => setBrands(value)}
              options={[
                { label: "All", value: "all" },
                ...(brandsList?.map((brand: Brand) => ({
                  label: brand.name,
                  value: brand.id.toString(),
                })) ?? []),
              ]}
              value={brands}
            />
          </Grid>
          <Grid size={4}>
            <SelectInput
              label="Product Form"
              onChange={(value: string) => setForm(value)}
              options={[
                { label: "All", value: "all" },
                ...Object.entries(FORM).map(([value, label]) => ({
                  label,
                  value,
                })),
              ]}
              value={form}
            />
          </Grid>
          <Grid size={4}>
            <SelectInput
              label="Microbe Standard"
              onChange={(value: string) => setMicrobeStandard(value)}
              options={[
                { label: "All", value: "all" },
                ...Object.entries(MICROBE_STANDARD).map(([value, label]) => ({
                  label,
                  value,
                })),
              ]}
              value={form}
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel<Formula>
                    columnName="nameInternal"
                    paginationSettings={paginationSettings}
                    toggleSort={toggleSort}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel<Formula>
                    columnName="market"
                    paginationSettings={paginationSettings}
                    toggleSort={toggleSort}
                  >
                    Market
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel<Formula>
                    columnName={"form"}
                    paginationSettings={paginationSettings}
                    toggleSort={toggleSort}
                  >
                    Product Form
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel<Formula>
                    columnName={"brandName"}
                    paginationSettings={paginationSettings}
                    toggleSort={toggleSort}
                  >
                    Brand
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel<Formula>
                    columnName={"microbeStandard"}
                    paginationSettings={paginationSettings}
                    toggleSort={toggleSort}
                  >
                    Microbe Standard
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
          <TablePagination<Formula>
            recordCount={totalRecordCount}
            paginationSettings={paginationSettings}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            rowsPerPageOptions={[100, 500, 750, 1000]}
          />
        </TableContainer>
      </Page>
    </>
  );
};

export default Formulas;
