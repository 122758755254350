import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { Leaflet, LeafletAudience, newLeaflet } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useLeafletQuery = (leafletId: number | undefined) =>
  useQuery<Leaflet>({
    queryKey: ["leaflets", leafletId],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/leaflets/${leafletId}/`, {
        signal,
      });
      return newLeaflet(response.data);
    },
    enabled: leafletId !== undefined,
  });

export const useFormulaLeafletsQuery = (formulaId: string | undefined) =>
  useQuery<Leaflet[]>({
    queryKey: ["leaflets", formulaId],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/leaflets/formula/${formulaId}/`, {
        signal,
      });
      return response.data.leaflets.map((leaflet: unknown) =>
        newLeaflet(leaflet),
      );
    },
    enabled: formulaId !== undefined,
  });

export const useCreateLeafletMutation = (formulaId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (fileList: File[]) => {
      const formData = new FormData();
      fileList.forEach((file) => formData.append("file", file));
      await axios.post(`/v1/leaflets/formula/${formulaId}/`, formData);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["leaflets"],
      });
    },
  });
};

interface ILeafletAudienceData {
  audience: LeafletAudience;
}

export const useEditLeafletAudienceMutation = (leafletId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ILeafletAudienceData) => {
      await axios.put(`/v1/leaflets/${leafletId}/audience/`, data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["leaflets"],
      });
    },
  });
};

interface IApprovalData {
  approved: boolean;
  qualityReference: string;
}

export const useApproveLeafletMutation = (leafletId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IApprovalData) => {
      await axios.put(`/v1/leaflets/${leafletId}/approval/`, data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["leaflets"],
      });
    },
  });
};
