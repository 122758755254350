import { SelectField } from "@curaleaf-international/components";

export const FORM = {
  CAPSULE: "Capsule",
  FLOWER: "Flower",
  OIL: "Oil",
  OINTMENT: "Ointement",
  PASTILLE: "Pastille",
  VAPE_CARTRIDGE: "Vape Cartridge",
  DEVICE: "Device",
};

interface IProps {
  fullWidth?: boolean;
  helperText?: string;
  label: string;
  multiple?: boolean;
  name: string;
  required?: boolean;
}

const FormField = ({
  fullWidth,
  helperText,
  label,
  multiple,
  name,
  required,
}: IProps) => (
  <SelectField
    autoComplete="off"
    fullWidth={fullWidth}
    helperText={helperText}
    label={label}
    multiple={multiple}
    name={name}
    options={Object.keys(FORM).map(([value, label]) => ({
      label,
      value,
    }))}
    required={required}
  />
);

export default FormField;
