import {
  Dropzone,
  FormLinks,
  Page,
  ToastContext,
} from "@curaleaf-international/components";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useContext, useState } from "react";
import { useLocation } from "wouter";

import { useCreateLeafletMutation, useFormulaQuery } from "src/queries";

interface IProps {
  formulaId: string;
}

const UploadLeaflet = ({ formulaId }: IProps) => {
  const { data: formula } = useFormulaQuery(formulaId);
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const [files, setFiles] = useState<File[]>([]);
  const { mutateAsync: upload, isPending } =
    useCreateLeafletMutation(formulaId);

  const onClick = async () => {
    try {
      await upload(files);
      addToast("Report uploaded", "success");
      setLocation(`/formulas/${formulaId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const onDrop = async (newFiles: FileList) => {
    setFiles(
      [...files, ...newFiles].filter((file) => file.type === "application/pdf"),
    );
  };

  return (
    <Page
      breadcrumbs={[
        { to: "/formulas/", label: "Formulas" },
        { to: `/formulas/${formulaId}/`, label: formula?.nameInternal ?? "" },
      ]}
      disablePadding={true}
      title="Upload Leaflet"
    >
      <CardContent>
        <Dropzone label="Add file(s)" onDrop={onDrop} />
        <List>
          {files.map((file) => (
            <ListItem
              key={file.name}
              secondaryAction={
                <IconButton
                  aria-label="delete"
                  edge="end"
                  onClick={() =>
                    setFiles(files.filter((checkFile) => checkFile !== file))
                  }
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={file.name} />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
      <CardActions>
        <LoadingButton
          color="primary"
          disabled={files.length === 0}
          loading={isPending}
          onClick={onClick}
          variant="contained"
        >
          Upload
        </LoadingButton>
        <FormLinks links={[{ label: "Back", to: `/formulas/${formulaId}/` }]} />
      </CardActions>
    </Page>
  );
};

export default UploadLeaflet;
