import { SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ProductRow from "src/pages/Formula/ProductRow";
import { useFormulasProductsQuery } from "src/queries";

interface IProps {
  formulaId: string;
}

const Products = ({ formulaId }: IProps) => {
  const { data: products } = useFormulasProductsQuery(formulaId);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>SKU</TableCell>
            <TableCell>GTIN</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products?.map((product) => (
            <ProductRow key={product.id} product={product} />
          )) ?? <SkeletonRow cols={5} />}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Products;
