import axios from "axios";

import { QualityCheck, newQualityCheck } from "src/models";
import { PaginationSettings, PaginatedResult } from "src/pagination";
import { useQuery } from "src/query";

export interface QualityChecksSearchParams {
  approved?: boolean;
  paginationSettings: Partial<PaginationSettings<QualityCheck>>;
  sortColumn?: "nameInternal" | "market" | "brand";
  searchTerm?: string;
}

export const useQualityChecksQuery = (params: QualityChecksSearchParams) => {
  const { rawSearchQuery: _ = "", ...paginationParams } =
    params.paginationSettings ?? {};

  return useQuery<PaginatedResult<QualityCheck>>({
    queryKey: ["quality-checks", paginationParams, params.approved],
    queryFn: async () => {
      const response = await axios.get("/v1/quality-checks/", {
        params: {
          ...paginationParams,
          approved: params.approved,
        },
      });
      return {
        rows: response.data.checks.map((data: unknown) =>
          newQualityCheck(data),
        ),
        totalRecordCount: response.data.totalRecordCount,
        paginationSettings: response.data.paginationSettings,
      };
    },
  });
};
