import { Page, ToastContext } from "@curaleaf-international/components";
import { useContext, useMemo } from "react";
import { useLocation } from "wouter";

import BrandForm, { ValidatedType } from "src/components/BrandForm";
import { useCreateBrandMutation } from "src/queries/brands";

const CreateBrand = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: create } = useCreateBrandMutation();
  const defaultValues = useMemo(
    () => ({
      company: "",
      name: "",
    }),
    [],
  );

  const onSubmit = async (data: ValidatedType) => {
    try {
      await create(data);
      addToast("Brand added", "success");
      setLocation("/brands/");
    } catch {
      addToast("Error adding brand", "error");
    }
  };

  return (
    <Page
      breadcrumbs={[
        {
          to: "/brands/",
          label: "Brands",
        },
      ]}
      disablePadding={true}
      title="Add brand"
    >
      <BrandForm
        defaultValues={defaultValues}
        label="Add"
        onSubmit={onSubmit}
      />
    </Page>
  );
};

export default CreateBrand;
