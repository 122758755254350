import { Page, ToastContext } from "@curaleaf-international/components";
import { useContext, useMemo } from "react";
import { useLocation } from "wouter";

import BrandForm, { ValidatedType } from "src/components/BrandForm";
import { useEditBrandMutation, useBrandQuery } from "src/queries";

interface IProps {
  brandId: number;
}

const EditBrand = ({ brandId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: brand } = useBrandQuery(brandId);
  const { mutateAsync: edit } = useEditBrandMutation(brandId);

  const defaultValues = useMemo(
    () => ({
      company: brand?.company ?? "",
      name: brand?.name ?? "",
    }),
    [brand !== undefined],
  );

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit(data);
      addToast("Brand edited", "success");
      setLocation("/brands/");
    } catch {
      addToast("Error editing brand", "error");
    }
  };

  return (
    <Page
      breadcrumbs={[
        {
          to: "/brands/",
          label: "Brands",
        },
        {
          to: `/brands/${brandId}/`,
          label: brand?.name ?? "",
        },
      ]}
      disablePadding={true}
      title="Edit brand"
    >
      <BrandForm
        defaultValues={defaultValues}
        disabled={brand === undefined}
        label="Edit"
        onSubmit={onSubmit}
      />
    </Page>
  );
};

export default EditBrand;
