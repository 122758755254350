import {
  cz,
  FormLinks,
  SelectField,
  SubmitButton,
  TextField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import CannaboidField from "src/components/FormulaForm/CannaboidField";
import MarketField from "src/components/MarketField";
import {
  FlowerGrade,
  Form,
  GmpGacp,
  MicrobeStandard,
  Species,
} from "src/models";
import { useBrandsQuery } from "src/queries";

const INGREDIENTS = [
  "*delta-9-tetrahydrocannabinol",
  "Cannabidiol Extract",
  "Cannabidiol Isolate",
  "Cannabidiol",
  "Cannabis sativa L*",
  "Cannabis sativa L. extract",
  "Cochineal red A (E124)",
  "containing Delta-9-tetrahydrocannabinol",
  "Delta-9-tetrahydrocannabinol",
  "Glucose",
  "Jack Herer Terpenes",
  "MCT Oil",
  "Propylene glycol (E1520)",
  "Sorbitol",
  "Sucrose",
  "Terpenes",
  "Tartrazine (E102)",
  "Triacetin (E1518)",
  "Triglycerides medium-chain",
  "ssp. Indica",
  "ssp. Sativa",
  "microcrystalline cellulose",
  "Sunset yellow FCF (E110)",
  "Olive Oil",
  "Cannabigerol",
];
const TERPENES = [
  "Caryophyllene",
  "D-Limonene",
  "R-limonene",
  "β-Myrcene",
  "α-pinene",
  "β-Pinene",
  "Linalool",
  "Humulene",
  "Delta-3-Carene",
  "Terpinolene",
  "trans-Ocimene",
  "β-Caryophyllene",
  "Guaiol",
  "α-Bisabolol",
  "α-Cendrene",
  "α-Humulene",
  "Limonene",
  "γ-Elemene",
  "Fenchol",
  "Ocimene",
  "Sabinene",
  "Farnesene",
  "trans-Neolidol",
  "trans-Caryophyllene",
  "Valencene",
  "E-Neridol",
  "Z-Neridiol",
  "Eucalyptol",
];

const FormSchema = z
  .object({
    appearance: cz.field(z.string().nullable()),
    aroma: cz.field(z.string().nullable()),
    brandId: cz.field(z.coerce.number()),
    carrier: cz.field(z.string().nullable()),
    cbdMin: cz.field(cz.decimal().nullable()),
    cbdMax: cz.field(cz.decimal().nullable()),
    cbgMin: cz.field(cz.decimal().nullable()),
    cbgMax: cz.field(cz.decimal().nullable()),
    cbnMin: cz.field(cz.decimal().nullable()),
    cbnMax: cz.field(cz.decimal().nullable()),
    controlled: cz.field(z.string().transform((val) => val === "true")),
    flavour: cz.field(z.string().nullable()),
    flowerGrade: cz.field(z.nativeEnum(FlowerGrade).nullable()),
    form: cz.field(z.nativeEnum(Form)),
    gmpGacp: cz.field(z.nativeEnum(GmpGacp).nullable()),
    grower: cz.field(z.string().nullable()),
    ingredients: cz.fieldArray(z.array(z.string())),
    intendedUse: cz.field(z.string().nullable()),
    irradiationType: cz.field(z.string().nullable()),
    manufacturer: cz.field(z.string().nullable()),
    market: cz.field(z.string().min(1)),
    microbeStandard: cz.field(z.nativeEnum(MicrobeStandard).nullable()),
    nameInternal: cz.field(z.string().min(1)),
    parentStrain: cz.field(z.string().nullable()),
    registeredName: cz.field(z.string().nullable()),
    species: cz.field(z.nativeEnum(Species).nullable()),
    strain: cz.field(z.string().nullable()),
    terpenes: cz.fieldArray(z.array(z.string())),
    thcMin: cz.field(cz.decimal().nullable()),
    thcMax: cz.field(cz.decimal().nullable()),
  })
  .transform((values) => {
    if (values.form === Form.FLOWER) {
      values.cbdMin = values.cbdMin?.times(10) ?? null;
      values.cbdMax = values.cbdMax?.times(10) ?? null;
      values.cbgMin = values.cbgMin?.times(10) ?? null;
      values.cbgMax = values.cbgMax?.times(10) ?? null;
      values.cbnMin = values.cbnMin?.times(10) ?? null;
      values.cbnMax = values.cbnMax?.times(10) ?? null;
      values.thcMin = values.thcMin?.times(10) ?? null;
      values.thcMax = values.thcMax?.times(10) ?? null;
    }
    return values;
  })
  .superRefine((values, ctx) => {
    if ((values.cbdMin === null) !== (values.cbdMax === null)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min and Max must both be defined or both absent",
        path: ["cbdMin"],
      });
    } else if (values.cbdMin?.greaterThan(values.cbdMax ?? 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min must be less than Max",
        path: ["cbdMin"],
      });
    }
    if ((values.cbgMin === null) !== (values.cbgMax === null)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min and Max must both be defined or both absent",
        path: ["cbgMin"],
      });
    } else if (values.cbgMin?.greaterThan(values.cbgMax ?? 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min must be less than Max",
        path: ["cbgMin"],
      });
    }
    if ((values.cbnMin === null) !== (values.cbnMax === null)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min and Max must both be defined or both absent",
        path: ["cbnMin"],
      });
    } else if (values.cbnMin?.greaterThan(values.cbnMax ?? 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min must be less than Max",
        path: ["cbnMin"],
      });
    }
    if ((values.thcMin === null) !== (values.thcMax === null)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min and Max must both be defined or both absent",
        path: ["thcMin"],
      });
    } else if (values.thcMin?.greaterThan(values.thcMax ?? 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Min must be less than Max",
        path: ["thcMin"],
      });
    }
  });
export type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  defaultValues: FormType;
  disabled?: boolean;
  label: string;
  onSubmit: (data: ValidatedType) => Promise<void>;
}

const FormulaForm = ({ defaultValues, disabled, label, onSubmit }: IProps) => {
  const { data: brands } = useBrandsQuery();

  const methods = useForm<FormType, any, ValidatedType>({
    defaultValues,
    disabled,
    resolver: zodResolver(FormSchema as any),
  });
  const formType = methods.watch("form");

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CardContent>
          <TextField
            autoComplete="off"
            fullWidth
            label="Internal name"
            name="nameInternal"
            required
          />
          <MarketField fullWidth label="Market" name="market" required />
          <TextField
            autoComplete="off"
            fullWidth
            label="Registered market name"
            name="registeredName"
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Brand"
            name="brandId"
            options={
              brands
                ?.slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((brand) => ({
                  label: brand.name,
                  value: brand.id.toString(),
                })) ?? []
            }
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Controlled"
            name="controlled"
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Form"
            name="form"
            options={Object.values(Form).map((value) => ({ value }))}
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Species"
            name="species"
            options={[
              { value: "", label: "" },
              ...Object.values(Species).map((value) => ({ value })),
            ]}
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Strain"
            name="strain"
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Ingredients"
            multiple
            name="ingredients"
            options={INGREDIENTS.map((value) => ({ value }))}
            required
          />

          <CannaboidField form={formType} name="thc" />
          <CannaboidField form={formType} name="cbd" />
          <CannaboidField form={formType} name="cbg" />
          <CannaboidField form={formType} name="cbn" />
          <TextField
            autoComplete="off"
            fullWidth
            label="Appearance"
            name="appearance"
          />
          <TextField autoComplete="off" fullWidth label="Aroma" name="aroma" />
          <TextField
            autoComplete="off"
            fullWidth
            label="Flavour"
            name="flavour"
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Grower"
            name="grower"
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Parent Strain"
            name="parentStrain"
          />
          {formType === "FLOWER" && (
            <>
              <SelectField
                autoComplete="off"
                fullWidth
                label="Microbial Standard"
                name="microbeStandard"
                options={[
                  { value: "" },
                  ...Object.values(MicrobeStandard).map((value) => ({
                    value,
                  })),
                ]}
              />
              <SelectField
                autoComplete="off"
                fullWidth
                label="Flower Grade"
                name="flowerGrade"
                options={[
                  { value: "" },
                  ...Object.values(FlowerGrade).map((value) => ({ value })),
                ]}
                required
              />
            </>
          )}
          {formType === "OIL" && (
            <>
              <TextField
                autoComplete="off"
                fullWidth
                label="Carrier"
                name="carrier"
              />
            </>
          )}
          {formType !== "PASTILLE" ? (
            <>
              <SelectField
                autoComplete="off"
                fullWidth
                label="Terpenes"
                multiple
                name="terpenes"
                options={TERPENES.map((value) => ({ value }))}
              />
            </>
          ) : null}
          <TextField
            autoComplete="off"
            fullWidth
            label="Intended Use"
            name="intendedUse"
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="GMP/GACP"
            name="gmpGacp"
            options={Object.values(GmpGacp).map((value) => ({ value }))}
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Manufacturer"
            name="manufacturer"
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Irradiation Type"
            name="irradiationType"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <SubmitButton label={label} />
          <FormLinks links={[{ label: "Back", to: "/formulas/" }]} />
        </CardActions>
      </form>
    </FormProvider>
  );
};

export default FormulaForm;
