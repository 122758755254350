import {
  cz,
  FormLinks,
  Page,
  SubmitButton,
  TextField,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import {
  useApproveFormulaHistoryMutation,
  useFormulaHistoryQuery,
} from "src/queries";

interface IProps {
  formulaHistoryId: string;
}

const FormSchema = z.object({
  qualityReference: cz.field(z.string().min(1)),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const ApproveFormulaHistory = ({ formulaHistoryId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: formulaHistory } = useFormulaHistoryQuery(formulaHistoryId);
  const { mutateAsync: approve } =
    useApproveFormulaHistoryMutation(formulaHistoryId);

  const defaultValues = {
    qualityReference: formulaHistory?.qualityReference ?? "",
  };
  const methods = useForm<FormType>({
    defaultValues,
    disabled: formulaHistory === undefined,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => methods.reset(defaultValues), [formulaHistory === undefined]);
  const label = formulaHistory?.approved === null ? "Approve" : "Disapprove";

  const onSubmit = async (data: ValidatedType) => {
    try {
      await approve({
        approved: formulaHistory!.approved === null,
        qualityReference: data.qualityReference,
      });
      setLocation(`/formulas/${formulaHistory!.formulaId}/`);
      addToast(`Formula ${label}d`, "success");
    } catch {
      addToast("Try Again", "error");
    }
  };

  return (
    <Page
      breadcrumbs={[
        { to: "/formulas/", label: "Formulas" },
        {
          to: `/formulas/${formulaHistory?.formulaId}/`,
          label: formulaHistory?.nameInternal ?? "",
        },
      ]}
      disablePadding={true}
      title="Approve formula"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CardContent>
            <TextField
              autoComplete="off"
              fullWidth
              label="Quality Reference"
              name="qualityReference"
              required
            />
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label={label} />
            <FormLinks
              links={[
                {
                  label: "Back",
                  to: `/formulas/${formulaHistory?.formulaId}/`,
                },
              ]}
            />
          </CardActions>
        </form>
      </FormProvider>
    </Page>
  );
};

export default ApproveFormulaHistory;
