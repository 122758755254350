import { sortByKey, SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import HistoryRow from "src/pages/Formula/HistoryRow";
import { useFormulaHistoriesQuery } from "src/queries";

interface IProps {
  formulaId: string;
}

const History = ({ formulaId }: IProps) => {
  const { data: histories } = useFormulaHistoriesQuery(formulaId);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Internal Name</TableCell>
            <TableCell>Quality Reference</TableCell>
            <TableCell>Entered by</TableCell>
            <TableCell>Approved by</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {histories
            ?.sort(sortByKey((formula) => [formula.timestamp]))
            .map((history) => (
              <HistoryRow key={history.id} history={history} />
            )) ?? <SkeletonRow cols={4} />}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default History;
