import {
  APIError,
  Page,
  ToastContext,
} from "@curaleaf-international/components";
import axios from "axios";
import { useContext, useMemo } from "react";
import { useLocation } from "wouter";

import FormulaForm, { ValidatedType } from "src/components/FormulaForm";
import { FlowerGrade, Form, GmpGacp } from "src/models";
import { useEditFormulaMutation, useFormulaQuery } from "src/queries";

interface IProps {
  formulaId: string;
}

const EditFormula = ({ formulaId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: formula } = useFormulaQuery(formulaId);
  const { mutateAsync: edit } = useEditFormulaMutation(formulaId);
  const defaultValues = useMemo(
    () => ({
      appearance: formula?.appearance ?? "",
      aroma: formula?.aroma ?? "",
      brandId: formula?.brandId.toString() ?? "1",
      carrier: formula?.carrier ?? "",
      cbdMin:
        formula?.form === Form.FLOWER
          ? (formula?.cbd?.[0].dividedBy(10).toString() ?? "")
          : (formula?.cbd?.[0].toString() ?? ""),
      cbdMax:
        formula?.form === Form.FLOWER
          ? (formula?.cbd?.[1].dividedBy(10).toString() ?? "")
          : (formula?.cbd?.[1].toString() ?? ""),
      cbgMin:
        formula?.form === Form.FLOWER
          ? (formula?.cbg?.[0].dividedBy(10).toString() ?? "")
          : (formula?.cbg?.[0].toString() ?? ""),
      cbgMax:
        formula?.form === Form.FLOWER
          ? (formula?.cbg?.[1].dividedBy(10).toString() ?? "")
          : (formula?.cbg?.[1].toString() ?? ""),
      cbnMin:
        formula?.form === Form.FLOWER
          ? (formula?.cbn?.[0].dividedBy(10).toString() ?? "")
          : (formula?.cbn?.[0].toString() ?? ""),
      cbnMax:
        formula?.form === Form.FLOWER
          ? (formula?.cbn?.[1].dividedBy(10).toString() ?? "")
          : (formula?.cbn?.[1].toString() ?? ""),
      controlled: formula?.controlled === true ? "true" : "false",
      flavour: formula?.flavour ?? "",
      flowerGrade: formula?.flowerGrade ?? FlowerGrade.STANDARD,
      form: formula?.form ?? Form.FLOWER,
      gmpGacp: formula?.gmpGacp ?? GmpGacp.GMP,
      grower: formula?.grower ?? "",
      ingredients: formula?.ingredients ?? [],
      intendedUse: formula?.intendedUse ?? "",
      irradiationType: formula?.irradiationType ?? "",
      manufacturer: formula?.manufacturer ?? "",
      market: formula?.market ?? "gbr",
      microbeStandard: formula?.microbeStandard ?? "",
      nameInternal: formula?.nameInternal ?? "",
      parentStrain: formula?.parentStrain ?? "",
      registeredName: formula?.registeredName ?? "",
      species: formula?.species ?? "",
      strain: formula?.strain ?? "",
      terpenes: formula?.terpenes ?? [],
      thcMin:
        formula?.form === Form.FLOWER
          ? (formula?.thc?.[0].dividedBy(10).toString() ?? "")
          : (formula?.thc?.[0].toString() ?? ""),
      thcMax:
        formula?.form === Form.FLOWER
          ? (formula?.thc?.[1].dividedBy(10).toString() ?? "")
          : (formula?.thc?.[1].toString() ?? ""),
    }),
    [formula],
  );

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        ...data,
        cbd:
          data.cbdMin === null || data.cbdMax === null
            ? null
            : [data.cbdMin, data.cbdMax],
        cbg:
          data.cbgMin === null || data.cbgMax === null
            ? null
            : [data.cbgMin, data.cbgMax],
        cbn:
          data.cbnMin === null || data.cbnMax === null
            ? null
            : [data.cbnMin, data.cbnMax],
        flowerGrade: data.form === Form.FLOWER ? data.flowerGrade : null,
        microbeStandard:
          data.form === Form.FLOWER ? data.microbeStandard : null,
        thc:
          data.thcMin === null || data.thcMax === null
            ? null
            : [data.thcMin, data.thcMax],
      });
      addToast("Formula edited", "success");
      setLocation(`/formulas/${formulaId}/`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const apiError = error.response?.data as APIError;
        if (apiError.code === "NAME_EXISTS") {
          addToast("This name has already been used.", "error");
        } else {
          addToast("An unexpected error occurred. Please try again.", "error");
        }
      } else {
        addToast("An unexpected error occurred. Please try again.", "error");
      }
    }
  };

  return (
    <Page
      breadcrumbs={[
        {
          to: "/formulas/",
          label: "Formulas",
        },
        {
          to: `/formulas/${formulaId}/`,
          label: formula?.nameInternal ?? "",
        },
        { label: "Edit formula" },
      ]}
      disablePadding={true}
      title="Edit formula"
    >
      <FormulaForm
        defaultValues={defaultValues}
        disabled={formula === undefined}
        label="Edit"
        onSubmit={onSubmit}
      />
    </Page>
  );
};

export default EditFormula;
