import { formatPercentage, Value } from "@curaleaf-international/components";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Decimal } from "decimal.js";

import { Form, Formula } from "src/models";

interface IValueProps {
  form?: Form;
  values?: [Decimal, Decimal] | null;
}

const CannaboidValue = ({ form, values }: IValueProps) => {
  if (values === undefined) {
    return <Skeleton />;
  } else if (values === null) {
    return <></>;
  } else if (!values[0].equals(values[1])) {
    switch (form) {
      case Form.FLOWER:
        return `${formatPercentage(values[0].dividedBy(1000))} – ${formatPercentage(values[1].dividedBy(1000))}`;
      case Form.OIL:
        return `${values[0]?.toString()} – ${values[1]?.toString()} mg/ml`;
      case null:
        return "";
      default:
        return `${values[0]?.toString()} – ${values[1]?.toString()} mg/unit`;
    }
  } else {
    switch (form) {
      case Form.FLOWER:
        return formatPercentage(values[1].dividedBy(1000));
      case Form.OIL:
        return `${values[1].toString()} mg/ml`;
      case null:
        return "";
      default:
        return `${values[1].toString()} mg/unit}`;
    }
  }
};

interface IProps {
  formula?: Formula;
}

const Details = ({ formula }: IProps) => (
  <Table>
    <TableBody>
      <TableRow>
        <TableCell>State</TableCell>
        <TableCell>
          <Value text={formula?.state} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Registered Market Name</TableCell>
        <TableCell>
          <Value text={formula?.registeredName} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Brand Name</TableCell>
        <TableCell>
          <Value text={formula?.brandName} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Market</TableCell>
        <TableCell>
          <Value text={formula?.market.toUpperCase()} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Company</TableCell>
        <TableCell>
          <Value text={formula?.company} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Form</TableCell>
        <TableCell>
          <Value text={formula?.form} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Carrier</TableCell>
        <TableCell>
          <Value text={formula?.carrier} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Controlled</TableCell>
        <TableCell>
          <Value bool={formula?.controlled} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Species</TableCell>
        <TableCell>
          <Value text={formula?.species ?? "NO SPECIES"} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Strain</TableCell>
        <TableCell>
          <Value text={formula?.strain} />
        </TableCell>
      </TableRow>

      {formula?.form !== Form.PASTILLE ? (
        <TableRow>
          <TableCell>Terpenes</TableCell>
          <TableCell>
            <Value text={formula?.terpenes.join(", ")} />
          </TableCell>
        </TableRow>
      ) : null}

      <TableRow>
        <TableCell>THC</TableCell>
        <TableCell>
          <CannaboidValue form={formula?.form} values={formula?.thc} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>CBD</TableCell>
        <TableCell>
          <CannaboidValue form={formula?.form} values={formula?.cbd} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>CBG</TableCell>
        <TableCell>
          <CannaboidValue form={formula?.form} values={formula?.cbg} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>CBN</TableCell>
        <TableCell>
          <CannaboidValue form={formula?.form} values={formula?.cbn} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Appearance</TableCell>
        <TableCell>
          <Value text={formula?.appearance} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Aroma</TableCell>
        <TableCell>
          <Value text={formula?.aroma} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Flavour</TableCell>
        <TableCell>
          <Value text={formula?.flavour} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Grower</TableCell>
        <TableCell>
          <Value text={formula?.grower} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Parent Strain</TableCell>
        <TableCell>
          <Value text={formula?.parentStrain} />
        </TableCell>
      </TableRow>
      {formula?.form === Form.FLOWER && (
        <>
          <TableRow>
            <TableCell>Microbial Standard</TableCell>
            <TableCell>
              <Value text={formula?.microbeStandard} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Flower Grade</TableCell>
            <TableCell>
              <Value text={formula?.flowerGrade} />
            </TableCell>
          </TableRow>
        </>
      )}
      <TableRow>
        <TableCell>Intended Use</TableCell>
        <TableCell>
          <Value text={formula?.intendedUse} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>GMP/GACP</TableCell>
        <TableCell>
          <Value text={formula?.gmpGacp} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Manufacturer</TableCell>
        <TableCell>
          <Value text={formula?.manufacturer} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Irradiation Type</TableCell>
        <TableCell>
          <Value text={formula?.irradiationType} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Ingredients</TableCell>
        <TableCell>
          <Value text={formula?.ingredients.join(", ")} />
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export default Details;
