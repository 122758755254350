import { DecimalField2 as DecimalField } from "@curaleaf-international/components";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

interface IProps {
  form: string;
  name: string;
}

const CannaboidField = ({ form, name }: IProps) => {
  let units = "mg/unit";
  if (form === "FLOWER") {
    units = "%";
  } else if (form === "OIL") {
    units = "mg/ml";
  }

  return (
    <Grid container spacing={2}>
      <Grid size={6}>
        <DecimalField
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{units}</InputAdornment>
            ),
          }}
          name={`${name}Min`}
          label={`${name.toUpperCase()} Min`}
          fullWidth
        />
      </Grid>
      <Grid size={6}>
        <DecimalField
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{units}</InputAdornment>
            ),
          }}
          name={`${name}Max`}
          label={`${name.toUpperCase()} Max`}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default CannaboidField;
