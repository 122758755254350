import { Page, ToastContext, Unit } from "@curaleaf-international/components";
import { useContext, useMemo } from "react";
import { useLocation } from "wouter";

import ProductForm from "src/components/ProductForm";
import { useCreateProductMutation, useFormulaQuery } from "src/queries";

interface IProps {
  formulaId: string;
}

const CreateProduct = ({ formulaId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: formula } = useFormulaQuery(formulaId);
  const { mutateAsync: create } = useCreateProductMutation();
  const defaultValues = useMemo(
    () => ({
      gtin: "",
      quantity: "",
      sku: "",
      unit: Unit.G,
    }),
    [],
  );

  const onSubmit = async (data: any) => {
    try {
      await create({
        formulaId,
        ...data,
      });
      addToast("Product added", "success");
      setLocation(`/formulas/${formulaId}/`);
    } catch {
      addToast("Error adding product", "error");
    }
  };

  return (
    <Page
      breadcrumbs={[
        {
          to: "/formulas/",
          label: "Formulas",
        },
        {
          to: `/formulas/${formulaId}/`,
          label: formula?.nameInternal ?? "",
        },
      ]}
      disablePadding={true}
      title="Add product"
    >
      <ProductForm
        defaultValues={defaultValues}
        label="Add"
        onSubmit={onSubmit}
      />
    </Page>
  );
};

export default CreateProduct;
