import { Page, ToastContext } from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext, useEffect, useState } from "react";
import { Redirect } from "wouter";

import { AuthContext } from "src/AuthContext";
import { useLoginMutation, useSSOLinkMutation } from "src/queries";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(ToastContext);
  const { authenticate, member } = useContext(AuthContext);
  const { mutateAsync: fetchLink } = useSSOLinkMutation();
  const { mutateAsync: login } = useLoginMutation();

  useEffect(() => {
    const alogin = async () => {
      const parsedHash = new URLSearchParams(window.location.hash.substring(1));
      const token = parsedHash.get("id_token");
      if (token !== null) {
        setLoading(true);
        try {
          await login(token);
          await authenticate();
        } catch {
          addToast("Login failed", "error");
        } finally {
          setLoading(false);
        }
      }
    };
    void alogin();
  }, []);

  const onClick = async () => {
    setLoading(true);
    const url = await fetchLink();
    window.location = url;
  };

  if (member !== undefined && member !== null) {
    return <Redirect to="/" />;
  } else {
    return (
      <Page disablePadding={true} title="Login">
        <CardContent>Login using your Microsoft credentials</CardContent>
        <Divider />
        <CardActions>
          <Button loading={loading} onClick={onClick} variant="contained">
            Login
          </Button>
        </CardActions>
      </Page>
    );
  }
};

export default Login;
