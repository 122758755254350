import { Page, ToastContext, Unit } from "@curaleaf-international/components";
import { useContext, useMemo } from "react";
import { useLocation } from "wouter";

import ProductForm from "src/components/ProductForm";
import { ValidatedType } from "src/components/ProductForm";
import {
  useEditProductMutation,
  useFormulaQuery,
  useProductQuery,
} from "src/queries";

interface IProps {
  productId: string;
}

const EditProduct = ({ productId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: product } = useProductQuery(productId);
  const { data: formula } = useFormulaQuery(product?.formulaId);
  const { mutateAsync: edit } = useEditProductMutation(productId);

  const defaultValues = useMemo(
    () => ({
      gtin: product?.gtin ?? "",
      quantity: product?.quantity.toString() ?? "",
      sku: product?.sku ?? "",
      unit: product?.unit ?? Unit.G,
    }),
    [product !== undefined],
  );

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        formulaId: product!.formulaId,
        ...data,
      });
      addToast("Product edited", "success");
      setLocation(`/formulas/${product?.formulaId}/`);
    } catch {
      addToast("Error editing product", "error");
    }
  };

  return (
    <Page
      breadcrumbs={[
        {
          to: "/products/",
          label: "Products",
        },
        {
          to: `/formulas/${product?.formulaId}/`,
          label: formula?.nameInternal ?? "",
        },
      ]}
      disablePadding={true}
      title="Edit product"
    >
      <ProductForm
        defaultValues={defaultValues}
        disabled={formula === undefined || product === undefined}
        label="Edit"
        onSubmit={onSubmit}
      />
    </Page>
  );
};

export default EditProduct;
