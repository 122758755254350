import { z } from "zod";

import { LeafletType } from "src/models/formula";

const qualityCheckSchema = z.object({
  approved: z.coerce.date().nullable(),
  formulaId: z.string().uuid(),
  leafletType: z.nativeEnum(LeafletType),
  nameInternal: z.string(),
  qualityReference: z.string().nullable(),
  registeredName: z.string().nullable(),
});

export type QualityCheck = z.infer<typeof qualityCheckSchema>;

export const newQualityCheck = (data: unknown): QualityCheck =>
  qualityCheckSchema.parse(data);
