import {
  Dropzone,
  FormLinks,
  Page,
  ToastContext,
} from "@curaleaf-international/components";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useContext, useState } from "react";
import { useLocation } from "wouter";

import { useUploadFormulaCSVMutation } from "src/queries";

const UploadFormulasCSV = () => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const [files, setFiles] = useState<File[]>([]);
  const { mutateAsync: upload, isPending } = useUploadFormulaCSVMutation();

  const onClick = async () => {
    try {
      const dataTransfer = new DataTransfer();
      files.forEach((file) => dataTransfer.items.add(file));
      await upload({ files: dataTransfer.files });
      addToast("Formulas uploaded", "success");
      setLocation(`/formulas/`);
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.status === 400 &&
        error.response?.data?.code === "INVALID_CSV"
      ) {
        const errorList = error.response.data.errors ?? [];
        const message = errorList
          .map((err: any) => {
            return err.error;
          })
          .join(". ");
        addToast(message, "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data?.code === "UNIQUE_VIOLATION"
      ) {
        const errorList = error.response.data.errors ?? [];
        const message = errorList
          .map((err: any) => {
            return err.error;
          })
          .join(". ");

        addToast(message, "error");
      } else {
        addToast("Upload failed", "error");
      }
      addToast("Try Again", "error");
    }
  };
  // eslint-disable-next-line @typescript-eslint/require-await
  const onDrop = async (newFiles: FileList) => {
    setFiles(
      [...files, ...newFiles].filter((file) => file.type === "text/csv"),
    );
  };
  return (
    <Page
      breadcrumbs={[{ to: "/formulas/", label: "Formulas" }]}
      disablePadding={true}
      title="Upload Formulas"
    >
      <CardContent>
        <Dropzone label="Add file(s)" onDrop={onDrop} />
        <List>
          {files.map((file) => (
            <ListItem
              key={file.name}
              secondaryAction={
                <IconButton
                  aria-label="delete"
                  edge="end"
                  onClick={() =>
                    setFiles(files.filter((checkFile) => checkFile !== file))
                  }
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={file.name} />
            </ListItem>
          ))}
        </List>

        <Typography variant="body1">
          The CSV should contain a line for every formula, with each line having
          the following data (columns) in the given order.
        </Typography>

        <List dense disablePadding sx={{ marginBottom: 4 }}>
          <ListSubheader>Formula data</ListSubheader>
          <ListItem>
            <ListItemText primary="Global Item SKU" secondary="Can be empty" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Unit Barcode" secondary="Can be empty" />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Global Product Name"
              secondary="Can be empty"
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Registered name" secondary="Can be empty" />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Formulary name"
              secondary="Cannot be empty, will error"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Country availability"
              secondary="Cannot be empty, options: UK, Channel Island"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Legacy Item Number"
              secondary="Can be empty"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Brand"
              secondary="Cannot be empty, has to be an existing brand in the system"
            />
          </ListItem>

          <ListItem>
            <ListItemText primary="Cultivator" secondary="Can be empty" />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="GMP/GACP"
              secondary="Can be empty, can be either GMP or GACP"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Compliant"
              secondary="Can be empty, has to be either EU_5_1_4, EU_5_1_8b, or EU_5_1_8c"
            />
          </ListItem>

          <ListItem>
            <ListItemText primary="Product category" secondary="Can be empty" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Sub Category" secondary="Can be empty" />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Flower Grade"
              secondary="Can be empty, options: Standard, Small, N/A"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Product Strength"
              secondary="Can be empty, should be in the form of: 'THC 12.5mg/ml CBD 25mg/ml' or 'THC 20% CBD <1%',
                accepts < and ranges"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Strain Type"
              secondary="Can be empty, options: Indica, Hybrid, Hybrid Indica, Hybrid Sativa, Sativa, N/A"
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Parent Strain" secondary="Can be empty" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Strain Name" secondary="Can be empty" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Terpene" secondary="Can be empty" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Ingredients" secondary="Can be empty" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Intended Use" secondary="Can be empty" />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Product Form"
              secondary="Cannot be empty, options: Non-Irradiated Flower, Irradiated Flower, Capsule, Ointment, Pastile, Device, Oral-Drops, Vape-Cartridge, Oil. If left blank will revert to Irradiated Flower."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="UOM"
              secondary="Cannot be empty, options: ML, G, MG, COUNT"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Fill weight"
              secondary="Cannot be empty, must be a whole number"
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Carrier" secondary="Can be empty" />
          </ListItem>
        </List>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          disabled={files.length === 0}
          loading={isPending}
          onClick={onClick}
          variant="contained"
        >
          Upload
        </Button>
        <FormLinks links={[{ label: "Back", to: `/formulas/` }]} />
      </CardActions>
    </Page>
  );
};
export default UploadFormulasCSV;
