import Grid from "@mui/material/Grid";

import { Formula } from "src/models";
import ImageCard from "src/pages/Formula/ImageCard";
import { useFormulaImagesQuery } from "src/queries";

interface IProps {
  formula?: Formula;
}

const Images = ({ formula }: IProps) => {
  const { data: image } = useFormulaImagesQuery(formula?.id);

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 6 }}>
        {formula?.productAssetKey ? (
          <ImageCard imageTitle={"Product"} imageUrl={image?.productUrl} />
        ) : (
          <>No product image available</>
        )}
      </Grid>

      <Grid size={{ xs: 12, sm: 6 }}>
        {formula?.packagingAssetKey ? (
          <ImageCard imageTitle={"Packaging"} imageUrl={image?.packagingUrl} />
        ) : (
          <>No packaging image available</>
        )}
      </Grid>
    </Grid>
  );
};

export default Images;
