import {
  APIError,
  Page,
  ToastContext,
} from "@curaleaf-international/components";
import axios from "axios";
import { useContext, useMemo } from "react";
import { useLocation } from "wouter";

import FormulaForm, { ValidatedType } from "src/components/FormulaForm";
import { FlowerGrade, Form, GmpGacp } from "src/models";
import { useCreateFormulaMutation } from "src/queries";

const CreateFormula = () => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { mutateAsync: create } = useCreateFormulaMutation();
  const defaultValues = useMemo(
    () => ({
      appearance: "",
      aroma: "",
      brandId: "",
      carrier: "",
      cbdMin: "",
      cbdMax: "",
      cbgMin: "",
      cbgMax: "",
      cbnMin: "",
      cbnMax: "",
      controlled: "true",
      flavour: "",
      flowerGrade: FlowerGrade.STANDARD,
      form: Form.FLOWER,
      gmpGacp: GmpGacp.GMP,
      grower: "",
      ingredients: [],
      intendedUse: "",
      irradiationType: "",
      manufacturer: "",
      market: "gbr",
      microbeStandard: "",
      nameInternal: "",
      parentStrain: "",
      registeredName: "",
      species: "",
      strain: "",
      terpenes: [],
      thcMin: "",
      thcMax: "",
    }),
    [],
  );

  const onSubmit = async (data: ValidatedType) => {
    try {
      await create({
        ...data,
        cbd:
          data.cbdMin === null || data.cbdMax === null
            ? null
            : [data.cbdMin, data.cbdMax],
        cbg:
          data.cbgMin === null || data.cbgMax === null
            ? null
            : [data.cbgMin, data.cbgMax],
        cbn:
          data.cbnMin === null || data.cbnMax === null
            ? null
            : [data.cbnMin, data.cbnMax],
        flowerGrade: data.form === Form.FLOWER ? data.flowerGrade : null,
        microbeStandard:
          data.form === Form.FLOWER ? data.microbeStandard : null,
        thc:
          data.thcMin === null || data.thcMax === null
            ? null
            : [data.thcMin, data.thcMax],
      });
      addToast("Formula added", "success");
      setLocation("/formulas/");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const apiError = error.response?.data as APIError;
        if (apiError.code === "NAME_EXISTS") {
          addToast("This name has already been used.", "error");
        } else {
          addToast("An unexpected error occurred. Please try again.", "error");
        }
      } else {
        addToast("An unexpected error occurred. Please try again.", "error");
      }
    }
  };

  return (
    <Page
      breadcrumbs={[
        {
          to: "/formulas/",
          label: "Formulas",
        },
        { label: "Add formula" },
      ]}
      disablePadding={true}
      title="Add formula"
    >
      <FormulaForm
        defaultValues={defaultValues}
        label="Add"
        onSubmit={onSubmit}
      />
    </Page>
  );
};

export default CreateFormula;
